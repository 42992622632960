
import OverlayPanel from 'primevue/overlaypanel'
import ListActions from '@/views/components/list/ListActions.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'

import apiUseCase from '@/usecase/apiUseCase'
import IResponse from '@/utils/interfaces/iResponse'

import { onMounted, reactive, ref, toRefs, defineComponent, computed, PropType, getCurrentInstance, watch } from 'vue'
import { useStore } from 'vuex'
import $ from 'jquery'

export default {
  name: 'ExpensesType',
  components: {
    OverlayPanel,
    ListActions,
    Paginator,
    InputIconRight,
    HjpCofirmModal
  },
  setup(props: any, { emit }: any) {
    const endpoint = ref('management/v1/MsExpensesType')

    const refOverlayAction = ref()
    const paginate = reactive({
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
    })
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const dataSource = ref([])
    const dataRow = ref(null) as any
    const store = useStore()
    const inputSearch = ref('')
    const placeholder = ref('Cari Disini...')

    const editTitle = 'Edit Jenis'
    const delTitle = 'Hapus Jenis'

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Hapus Jenis Pengeluaran?',
      subTitle: 'Menghapus Jenis Pengeluaran akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus Jenis Pengeluaran?',
      btnSubmitTitle: 'Hapus Jenis Pengeluaran',
      imgContent: require('@/assets/img/modal-confirmation/delete-default.svg')
    })

    const setDataRowOnRedux = () => {
      console.log('dataRow', dataRow.value)
      store.dispatch('storeRowDataTable', dataRow.value)
    }

    const showForm = () => {
      console.log('showForm')
      store.dispatch('setShowModalFormDl', true)
    }

    const onEdit = () => {
      console.log('edit')
      setDataRowOnRedux()
      showForm()
      // eslint-disable-next-line no-use-before-define
      hideOverlay()
    }

    const onDelete = (val: any) => {
      attrModalConfirmation.show = true
      setDataRowOnRedux()
      // eslint-disable-next-line no-use-before-define
      hideOverlay()
    }

    const onSearch = () => {
      // console.log('onSearch', value)
      // inputSearch.value = value
      // eslint-disable-next-line no-use-before-define
      getData()
    }

    const showDropDown = (evt: any, item: any) => {
      console.log('evt', evt)
      console.log('item', item)
      dataRow.value = item
      console.log('overlay', refOverlayAction.value)
      refOverlayAction.value.toggle(evt)
    }

    const hideOverlay = () => {
      refOverlayAction.value.hide()
    }

    const getData = () => {
      store.dispatch('showLoading')
      const arrParams = []
      let paramsString = ''

      if (inputSearch.value) {
        arrParams.push(`name=${inputSearch.value}`)
      }
      if (paginate.pageNumber > 0) {
        arrParams.push(`pageNumber=${paginate.pageNumber}`)
      }
      if (paginate.pageSize > 0) {
        arrParams.push(`pageSize=${paginate.pageSize}`)
      }
      paramsString = `?${arrParams.join('&')}`
      apiUseCase.get(`${endpoint.value}${paramsString}`).then((response) => {
        console.log('response', response)
        dataSource.value = response.result
        console.log('dataSource', dataSource)
        paginate.totalRecords = response.count
        console.log('paginate', paginate)
        // isLoading.value = false
      }).catch((err: any) => {
        // isLoading.value = false
      })
      store.dispatch('hideLoading')
    }

    const onChangePage = (evt: any) => {
      // console.log('evt', evt)
      paginate.pageNumber = evt.page + 1
      getData()
      const container = $('.wrap-hjp-data-list')
      $(window).scrollTop(container.offset().top)
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const onSubmitConfirmation = (val: any = '') => {
      console.log('submitFromDialog')
      console.log('val', val)
      store.dispatch('showLoading')
      const deletedId = val === '' ? dataRow.value.Id : val
      apiUseCase.delete(endpoint.value, deletedId).then((res: IResponse) => {
        console.log('response delete', res)
        if (res.error) {
          $toast.add({
            severity: 'error',
            summary: 'Tidak dapat menghapus Jenis Pengeluaran',
            detail: 'Jenis Pengeluaran sudah digunakan oleh dan tidak dapat dihapus.',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'success',
            summary: 'Berhasil menghapus Jenis Pengeluaran',
            detail: 'Jenis Pengeluaran sudah dihapus oleh sistem dan tidak dapat digunakan kembali.',
            group: 'bc',
            closable: false,
            life: 3000
          })
          getData()
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      getData()
      console.log('haha', store.state)
    })

    watch(() => store.getters.reloadTable, (val) => {
      if (val) {
        getData()
      }
    })

    return {
      editTitle,
      delTitle,
      onEdit,
      onDelete,
      showDropDown,
      refOverlayAction,
      dataSource,
      paginate,
      onChangePage,
      onSearch,
      placeholder,
      inputSearch,
      attrModalConfirmation,
      hideDialogConfirmation,
      onSubmitConfirmation
    }
  }
}
