import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row-reverse mb-5" }
const _hoisted_2 = { class: "lg:w-1/4 w-full" }
const _hoisted_3 = { class: "flex items-center justify-between border-bottom-grey-2 py-3" }
const _hoisted_4 = { class: "expense-type-list" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "font-semibold mr-3" }
const _hoisted_7 = {
  key: 0,
  class: "px-2 rounded text-white active"
}
const _hoisted_8 = {
  key: 1,
  class: "px-2 rounded text-white inactive"
}
const _hoisted_9 = { class: "text-grey" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputIconRight = _resolveComponent("InputIconRight")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_ListActions = _resolveComponent("ListActions")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_HjpCofirmModal = _resolveComponent("HjpCofirmModal")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputIconRight, {
          class: "input-search-header-custom",
          placeholder: $setup.placeholder,
          modelValue: $setup.inputSearch,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.inputSearch) = $event)),
          canClick: true,
          icon: _ctx.$icon.svg.searchEva,
          onSubmitInput: $setup.onSearch,
          onKeyup: _withKeys($setup.onSearch, ["enter"])
        }, null, 8, ["placeholder", "modelValue", "icon", "onSubmitInput", "onKeyup"])
      ])
    ]),
    _createElementVNode("div", null, [
      ($setup.dataSource.length === 0)
        ? (_openBlock(), _createBlock(_component_EmptyState, { key: 0 }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.dataSource, (data) => {
            return (_openBlock(), _createElementBlock("div", {
              key: data.Id,
              class: ""
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h6", _hoisted_6, _toDisplayString(data.Name), 1),
                    (data.IsActive)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Aktif"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, "Tidak Aktif"))
                  ]),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(data.Description), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("i", {
                    class: "pi pi-ellipsis-h text-base cursor-pointer text-black",
                    "aria:haspopup": "true",
                    onClick: ($event: any) => ($setup.showDropDown($event, data))
                  }, null, 8, _hoisted_10)
                ])
              ])
            ]))
          }), 128))
    ]),
    _createElementVNode("div", null, [
      ($setup.paginate.totalRecords > 0)
        ? (_openBlock(), _createBlock(_component_Paginator, {
            key: 0,
            rows: $setup.paginate.pageSize,
            totalRecords: $setup.paginate.totalRecords,
            onOnPage: $setup.onChangePage
          }, null, 8, ["rows", "totalRecords", "onOnPage"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_OverlayPanel, { ref: "refOverlayAction" }, {
      default: _withCtx(() => [
        _createVNode(_component_ListActions, {
          edit: true,
          del: true,
          editTitle: $setup.editTitle,
          delTitle: $setup.delTitle,
          onDel: $setup.onDelete,
          onEdit: $setup.onEdit
        }, null, 8, ["editTitle", "delTitle", "onDel", "onEdit"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_HjpCofirmModal, {
      show: $setup.attrModalConfirmation.show,
      imgContent: $setup.attrModalConfirmation.imgContent,
      title: $setup.attrModalConfirmation.title,
      subTitle: $setup.attrModalConfirmation.subTitle,
      btnSubmitTitle: $setup.attrModalConfirmation.btnSubmitTitle,
      btnBackTitle: $setup.attrModalConfirmation.btnBackTitle,
      onHideDialog: $setup.hideDialogConfirmation,
      onOnSubmit: $setup.onSubmitConfirmation
    }, null, 8, ["show", "imgContent", "title", "subTitle", "btnSubmitTitle", "btnBackTitle", "onHideDialog", "onOnSubmit"])
  ]))
}