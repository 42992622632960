import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = { class: "mt-5 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHjpTable = _resolveComponent("HeaderHjpTable")!
  const _component_HjpTabView = _resolveComponent("HjpTabView")!
  const _component_FixedExpenses = _resolveComponent("FixedExpenses")!
  const _component_ExpensesType = _resolveComponent("ExpensesType")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ExpenseTypeForm = _resolveComponent("ExpenseTypeForm")!
  const _component_HjpSideBar = _resolveComponent("HjpSideBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, { class: "card-no-shadow wrap-hjp-data-list" }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_HeaderHjpTable, {
            titleHeader: $setup.params.headerTitle,
            extraButton: $setup.params.extraButton,
            titleButton: $setup.params.buttonTitle,
            titleExtraButton: $setup.params.extraButtonTitle,
            showSearch: false,
            onRedirectAdd: $setup.onClickBtnAdd,
            onRedirectAddExtraButton: $setup.onClickBtnAddExtraButton
          }, null, 8, ["titleHeader", "extraButton", "titleButton", "titleExtraButton", "onRedirectAdd", "onRedirectAddExtraButton"])
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_HjpTabView, {
          onTabsChange: $setup.tabsChange,
          tabHeader: $setup.tabHeader
        }, null, 8, ["onTabsChange", "tabHeader"]),
        _createElementVNode("div", _hoisted_2, [
          ($setup.positionTabs === 0)
            ? (_openBlock(), _createBlock(_component_FixedExpenses, { key: 0 }))
            : ($setup.positionTabs === 1)
              ? (_openBlock(), _createBlock(_component_ExpensesType, { key: 1 }))
              : _createCommentVNode("", true)
        ])
      ]),
      footer: _withCtx(() => []),
      _: 1
    }),
    _createVNode(_component_HjpSideBar, {
      show: $setup.showModalForm,
      onHideDialog: $setup.hideModalForm,
      confirmDialogDelete: $setup.confirmDialogDelete,
      onOnDelete: $setup.submitFromDialog
    }, _createSlots({ _: 2 }, [
      ($setup.showModalForm)
        ? {
            name: "form",
            fn: _withCtx(() => [
              _createVNode(_component_ExpenseTypeForm)
            ])
          }
        : undefined
    ]), 1032, ["show", "onHideDialog", "confirmDialogDelete", "onOnDelete"])
  ]))
}