import { defineComponent, ref, watch, onMounted, computed, getCurrentInstance, reactive } from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import apiUseCase from '@/usecase/apiUseCase'
import IResponse from '@/utils/interfaces/iResponse'

// Components
import Card from 'primevue/card'
import InputSwitch from 'primevue/inputswitch'
import OverlayPanel from 'primevue/overlaypanel'
import ListActions from '@/views/components/list/ListActions.vue'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import EmptyState from '@/views/components/list/EmptyState.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import { PageState } from 'primevue/paginator'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

const FixedExpensestable = defineComponent({
  name: 'FixedExpensestable',
  components: {
    Card,
    InputSwitch,
    OverlayPanel,
    ListActions,
    InputIconRight,
    HjpCofirmModal,
    EmptyState,
    Paginator
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    // const store = useStore()
    const op = ref()
    const store = useStore()
    const inputSearch = ref('')
    const placeholder = ref('Cari Disini...')

    const paginate = reactive({
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
    })
    const endpoint = 'management/v1/MsExpenses'
    const baseRoute = '/hjp/master-fixed-expenses/form'
    const dataSource = ref([]) as any
    const dataRow = ref(null) as any

    const mappingDataSource = (data: any) => {
      const temp = ref()
      dataSource.value = []
      // eslint-disable-next-line array-callback-return
      data.map((x: any) => {
        x.showedTypes = x.Details.length > 2 ? x.Details.slice(0, 2) : x.Details
        x.countHiddenTypes = x.Details.length > 2 ? x.Details.length - 2 : 0
        temp.value = x
        dataSource.value.push(temp.value)
      })
    }

    const setDataRowOnRedux = () => {
      store.dispatch('storeRowDataTable', dataRow.value)
    }

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Hapus Pengeluaran?',
      subTitle: 'Menghapus Pengeluaran akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus Pengeluaran?',
      btnSubmitTitle: 'Hapus Pengeluaran',
      imgContent: require('@/assets/img/modal-confirmation/delete-default.svg')
    })

    const getAllData = async () => {
      store.dispatch('showLoading')
      const { result, error, count } = await apiUseCase.get(`${endpoint}${returnUrlPrams({
        search: inputSearch.value,
        filterField: ['name'],
        pageNumber: paginate.pageNumber,
        pageSize: paginate.pageSize
      })}`)
      if (!error) {
        mappingDataSource(result)
        paginate.totalRecords = count
      }
      store.dispatch('hideLoading')
    }

    const showDropDown = (evt: any, item: any) => {
      dataRow.value = item
      op.value.toggle(evt)
    }

    const onDelete = () => {
      setDataRowOnRedux()
      attrModalConfirmation.show = true
    }

    const onEdit = () => {
      setDataRowOnRedux()
      router.push({
        path: `${baseRoute}/${dataRow.value.Id}`
      })
    }

    const onSearch = () => {
      getAllData()
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const onSubmitConfirmation = (val: any = '') => {
      store.dispatch('showLoading')
      const deletedId = val === '' ? dataRow.value.Id : val
      apiUseCase.delete(endpoint, deletedId).then((res: IResponse) => {
        if (res.error) {
          $toast.add({
            severity: 'error',
            summary: 'Tidak dapat menghapus Pengeluaran',
            detail: 'Pengeluaran sudah digunakan dan tidak dapat dihapus.',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'success',
            summary: 'Berhasil menghapus Pengeluaran',
            detail: 'Pengeluaran sudah dihapus sistem dan tidak dapat digunakan kembali.',
            group: 'bc',
            closable: false,
            life: 3000
          })
          getAllData()
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const changeIsActive = async (val: any) => {
      store.dispatch('showLoading')
      const data = {
        // Id: val.Id,
        IsActive: val.IsActive,
        IsDelete: val.IsDeleted
      }
      const response = await apiUseCase.patch(endpoint, val.Id, data)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: 'Data berhasil diubah',
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
        val.IsActive = !val.IsActive
      }
      store.dispatch('hideLoading')
    }

    const onChangePage = ({ page }: PageState) => {
      paginate.pageNumber = page + 1
      getAllData()
    }

    onMounted(() => {
      getAllData()
    })

    return {
      showDropDown,
      op,
      onDelete,
      onEdit,
      inputSearch,
      placeholder,
      onSearch,
      dataSource,
      attrModalConfirmation,
      onSubmitConfirmation,
      hideDialogConfirmation,
      changeIsActive,
      paginate,
      onChangePage
    }
  }
})

export default FixedExpensestable
