
import {
  ref, watch, defineComponent, PropType, toRefs, reactive, computed, getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import Card from 'primevue/card'
import HeaderHjpTable from '@/views/components/header/HeaderHjpTable.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import ListActions from '@/views/components/list/ListActions.vue'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import OverlayPanel from 'primevue/overlaypanel'
import IResponse from '@/utils/interfaces/iResponse'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import ExpensesType from '@/views/pages/master-setting/expenses/expenses-type/ExpensesType.vue'
import ExpenseTypeForm from '@/views/pages/master-setting/expenses/expenses-type/Form.vue'
import FixedExpenses from '@/views/pages/master-setting/expenses/fixed-expenses/FixedExpenses.vue'
import apiUseCase from '@/usecase/apiUseCase'

export default {
  components: {
    Card,
    HjpTabView,
    ExpensesType,
    HeaderHjpTable,
    HjpSideBar,
    ExpenseTypeForm,
    FixedExpenses
    // OverlayPanel,
    // Paginator,
    // HjpTabView
  },

  setup() {
    const positionTabs = ref(0)
    const router = useRouter()
    const tabHeader = [
      { title: 'Pengeluaran Tetap', value: 0 },
      { title: 'Jenis Pengeluaran', value: 1 }
    ]

    const endpoint = ref('management/v1/MsExpensesType')

    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties

    const params = reactive({
      extraButton: true,
      baseRoute: 'master-fixed-expenses/form',
      headerTitle: 'Master Pengeluaran Tetap',
      buttonTitle: 'Tambah Pengeluaran',
      extraButtonTitle: 'Tambah Jenis',
    })

    const confirmDialogDelete = ref({
      title: 'Hapus Jenis Pengeluaran?',
      subTitle: 'Menghapus Jenis Pengeluaran akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus Jenis Pengeluaran?',
      btnSubmitTitle: 'Hapus Jenis Pengeluaran',
      imgContent: require('@/assets/img/modal-confirmation/delete-default.svg')
    })

    const store = useStore()

    const showModalForm = computed({
      get: () => store.state.hjpDataList.modalForm.show,
      set: (value) => {
        store.dispatch('setShowModalFormDl', value)
      }
    })

    const hideModalForm = () => {
      showModalForm.value = false
    }

    const showModalFormFunction = () => {
      console.log('showFormParent')
      showModalForm.value = true
    }

    const tabsChange = (val: any) => {
      positionTabs.value = val
      console.log('masuk change', val)
    }

    const onClickBtnAdd = (val: any) => {
      console.log('onClickBtnAdd', val)
      store.dispatch('storeRowDataTable', null)
      router.push({
        path: params.baseRoute
      })
      // store.dispatch('flushRowDataTable')
      // showModalForm.value = true
    }

    const onClickBtnAddExtraButton = (val: any) => {
      console.log('onClickBtnAddExtraButton', val)
      store.dispatch('flushRowDataTable')
      showModalForm.value = true
    }

    const onSearch = (value: any) => {
      console.log('onSearch', value)
      // inputSearch.value = value
      // getData()
    }

    const submitFromDialog = (val: any = null) => {
      console.log('deletedelete', val)
      store.dispatch('showLoading')
      const deletedId = val ?? store.state.hjpTable.modal.data.Id
      apiUseCase.delete(endpoint.value, deletedId).then((res: IResponse) => {
        console.log('response delete', res)
        if (res.error) {
          $toast.add({
            severity: 'error',
            summary: 'Tidak dapat menghapus Jenis Pengeluaran',
            detail: 'Jenis Pengeluaran sudah digunakan oleh dan tidak dapat dihapus.',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'success',
            summary: 'Berhasil menghapus Jenis Pengeluaran',
            detail: 'Jenis Pengeluaran sudah dihapus oleh sistem dan tidak dapat digunakan kembali.',
            group: 'bc',
            closable: false,
            life: 3000
          })
          store.dispatch('setReloadDataTable', true)
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      store.dispatch('hideLoading')
    }

    return {
      tabsChange,
      tabHeader,
      positionTabs,
      onClickBtnAdd,
      params,
      onClickBtnAddExtraButton,
      hideModalForm,
      showModalForm,
      confirmDialogDelete,
      submitFromDialog,
      showModalFormFunction
    }
  }
}
